.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 40px;
  gap: 12px;
}

.page-num {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-weight: 800;
  cursor: pointer;
  color: #adb5bd;
}
.page-link {
  padding: 14px;
}

.page-num:hover {
  background-color: #e5f0fd;
}
.active {
  background-color: #0c4da2;
  color: white;
}
.active:hover {
  background-color: #0c4da2;
  color: white;
}
